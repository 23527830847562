import React from 'react'
import { Link } from 'react-router-dom'
import './DesktopNav.css'
import { scrollToTop } from '../utils.js'

const DesktopNav = () => {
  return (
    <nav className="DesktopNav">
      <Link onClick={scrollToTop} className="DesktopNav__Brand" to="/">
        The Code Boutique
      </Link>
    </nav>
  )
}

export default DesktopNav
