import React from 'react'
import NavLink from '../Components/NavLink'
import style from './LandingPage.module.css'

const LandingPage = () => {
  return (
    <div className="Page Page--landing">
      <main>
        <section
          className="section screen"
          style={{ position: 'relative', minHeight: '70vh' }}
        >
          <div className={style.Arrow}>
            <NavLink to="#nav">
              <div className={style.ArrowTip}></div>
            </NavLink>
          </div>
        </section>
      </main>
    </div>
  )
}

export default LandingPage
