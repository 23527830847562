import React from 'react'
import { Helmet } from 'react-helmet'
import './App.css'
import LandingPage from './Pages/LandingPage.js'
import Privacy from './Pages/Privacy.js'
import ToC from './Pages/ToC.js'
import DesktopNav from './Components/DesktopNav.js'
import Footer from './Components/Footer.js'
import { BrowserRouter as Router, Route } from 'react-router-dom'

const App = () => {
  return (
    <Router>
      <div className="App">
        <Helmet
          lang="en"
          defaultTitle="The Code Boutique"
          titleTemplate="%s | The Code Boutique"
        >
          <meta
            name="description"
            concent="A software agency: Requirement specifications, Code and technology review, Technical business consulting, Application development."
          />
        </Helmet>
        <DesktopNav />
        <Route path="/" exact component={LandingPage} />
        <Route path="/terms" component={ToC} />
        <Route path="/privacy" component={Privacy} />
        <Footer />
      </div>
    </Router>
  )
}

export default App
