import scrollToElement from 'scroll-to-element'

export function scrollToTop(delay = 200) {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, delay)
}

export function scrollTo(element, offset = -120, duration = 300) {
  scrollToElement(element, { offset, duration })
}
