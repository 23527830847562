import React from 'react'
import { NavHashLink } from 'react-router-hash-link'
import { scrollToTop, scrollTo } from '../utils.js'

const NavLink = ({
  onClick: propOnClick,
  children,
  to,
  scroll,
  style = {},
}) => {
  const onClick = to.includes('#') ? () => {} : () => setImmediate(scrollToTop)
  const onClick_ = propOnClick
    ? () => {
        propOnClick()
        onClick()
      }
    : onClick
  return (
    <NavHashLink
      smooth
      scroll={scroll || scrollTo}
      to={to}
      onClick={onClick_}
      className={style.Link}
      activeClassName={style.ActiveLink}
      location={{
        pathname: document.location.pathname + document.location.hash,
      }}
    >
      {children}
    </NavHashLink>
  )
}

export default NavLink
