import React from 'react'
import style from './Footer.module.css'
import FooterLink from './NavLink.js'
import linkStyles from './FooterLink.module.css'

const Footer = () => (
  <div className={style.Wrapper}>
    <div className={style.Content}>
      <nav id="nav">
        <h5 className={style.Title}>Navigate</h5>
        <ul className={style.List}>
          <li>
            <FooterLink to="/terms" style={linkStyles}>
              Terms & Conditions
            </FooterLink>
          </li>
          <li>
            <FooterLink to="/privacy" style={linkStyles}>
              Privacy
            </FooterLink>
          </li>
        </ul>
        <a
          href="https://www.codementor.io/freelance-developers/vue?utm_source=certified_badge&referral=mblarsen-nd5nxbvc0"
          rel="noopener"
          className={style.Badge}
        >
          <img
            src="https://assets.codementor.io/cmx-dev-badge.svg"
            alt="Certficate badge"
          />
        </a>
      </nav>

      <div>
        <h5 className={style.Title}>Address</h5>
        <address>
          The Code Boutique, LLC
          <br />
          Michael Bøcker-Larsen sole member
          <br />
          347 5th ave ste 1402-382
          <br />
          New York, NY 10016
          <br />
          United States
        </address>
      </div>

      <div className={style.About}>
        <h5 className={style.Title}>About</h5>
        <p>
          The Code Boutique is{' '}
          <a href="https://www.linkedin.com/in/mblarsen/">
            Michael Bøcker-Larsen
          </a>{' '}
          at the helm. Mostly working alone but often in collaboration with
          senior developers, drawing expertice where it is needed. We opened
          shop more than {new Date().getFullYear() - 2010} years ago.
        </p>
      </div>
    </div>

    <div className={style.Copyright}>
      &copy; 2010 - {new Date().getFullYear()} The Code Boutique, LLC
    </div>
  </div>
)

export default Footer
